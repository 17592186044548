import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Button, Grid, Skeleton } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  FreeTextInput,
  NotificationCard,
  OptionalText,
  RequiredOption,
  ToggleButtonGroup,
  YesNoImNotSure,
  pleaseEnterAValidMessage,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { ReactElement, useRef, useState } from "react";
import { FormProvider, useController, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import RacwaMaps, { Marker, RacwaMapsMethods } from "../../shared/components/RacwaMaps";
import RacwaMapsSearch from "../../shared/components/RacwaMapsSearch";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { WhereAndHowQuestions, whereAndHowItHappenedPageTitle } from "./constants";
import { ProvideTheLocation, WhereAndHowFormProps, WhereAndHowFormValues } from "./types";

const { whereTheIncidentHappenedDescription, howTheIncidentHappened, werePoliceInvolved, policeReportNumber } =
  WhereAndHowQuestions;

const PoliceReportNumberMaxLength = 50;

export const WhereAndHowForm = ({ form, onSubmit, googleMapsApiKey }: WhereAndHowFormProps) => {
  const {
    handleSubmit,
    resetField,
    getValues,
    setValue,
    formState: { isSubmitting },
    control,
  } = form;

  const hasPoliceDetails = useWatch({ control, name: werePoliceInvolved.name });

  const nextButtonId = "submit-button";

  const selectedProvideTheLocation = useWatch({ control, name: "provideTheLocation" });

  const [googleMapsAvailable, setGoogleMapsAvailable] = useState<boolean>(true);

  const [hasShownZoomError, setHasShownZoomError] = useState<boolean>(false);

  useController<WhereAndHowFormValues, "whereTheIncidentHappenedMap">({
    control,
    name: "whereTheIncidentHappenedMap",
    rules: {
      validate: (value?: Marker) => {
        return (
          getValues(WhereAndHowQuestions.provideTheLocation.name) === ProvideTheLocation.DescribeLocation ||
          (!!value && !!value.zoom && value.zoom >= 18)
        );
      },
    },
  });

  const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) {
      if (googleMapsAvailable) {
        gtm(event("Maps is unavailable. Please select 'Describe location' instead"));
      }
      setGoogleMapsAvailable(false);

      return (
        <NotificationCard
          severity="error"
          message={{
            title: "Maps is unavailable. Please select 'Describe location' instead.",
          }}
        />
      );
    } else {
      return (
        <Skeleton
          sx={{ borderRadius: "3px", height: "400px" }}
          id="rac-home-page-button-skeleton"
          variant="rectangular"
          animation="wave"
          width="100%"
        />
      );
    }
  };

  const mapsRef = useRef<RacwaMapsMethods>(null);
  const getMapsRef = () => {
    if (mapsRef.current !== null) {
      return mapsRef.current;
    }
  };

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle title={whereAndHowItHappenedPageTitle} id="where-and-how-header" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              id={WhereAndHowQuestions.provideTheLocation.id}
              name={WhereAndHowQuestions.provideTheLocation.name}
              label={WhereAndHowQuestions.provideTheLocation.label}
              options={ProvideTheLocation}
              errorMessage={"Please select an option"}
              onChange={(e, v) => {
                trackCustomFormotivInput(WhereAndHowQuestions.provideTheLocation.label + " - " + v, "button", v);
                gtm(fieldTouched("Where the incident happened"));
                gtm(event(`Where the incident happened - ${v} `));
              }}
            />
          </Grid>

          {selectedProvideTheLocation === ProvideTheLocation.UseAMap && (
            <>
              <Grid item xs={12}>
                <Wrapper apiKey={googleMapsApiKey} render={render} libraries={["places", "geocoding"]}>
                  <RacwaMapsSearch
                    id="location-search-test"
                    label={WhereAndHowQuestions.whereTheIncidentHappenedMap.label}
                    sublabel="Place the bottom of the pin where the crash happened."
                    placeholder="Start typing to search"
                    apiKey={googleMapsApiKey}
                    onLocationSelected={(placeId) => {
                      const mapsRef = getMapsRef();
                      if (mapsRef) {
                        mapsRef.goToPlaceId(placeId);
                      }
                    }}
                  />
                  <div id={WhereAndHowQuestions.whereTheIncidentHappenedMap.id}>
                    <RacwaMaps
                      height="360px"
                      apiKey={googleMapsApiKey}
                      id={WhereAndHowQuestions.whereTheIncidentHappenedMap.id}
                      data-testid={`${WhereAndHowQuestions.whereTheIncidentHappenedMap.id}-test-id`}
                      value={getValues(WhereAndHowQuestions.whereTheIncidentHappenedMap.name) as Marker}
                      onLocationSelected={(val) => {
                        setValue(WhereAndHowQuestions.whereTheIncidentHappenedMap.name, val, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      mapType="hybrid"
                      ref={mapsRef}
                      data-hj-suppress
                    />
                  </div>
                </Wrapper>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "8px" }}>
                {form.getFieldState(WhereAndHowQuestions.whereTheIncidentHappenedMap.name).error &&
                  googleMapsAvailable &&
                  form.formState.isSubmitted && (
                    <NotificationCard
                      severity="error"
                      message={{
                        title: "Please zoom in more so we can see where this happened",
                      }}
                    />
                  )}
              </Grid>
            </>
          )}
          {selectedProvideTheLocation === ProvideTheLocation.DescribeLocation && (
            <Grid item xs={12}>
              <FreeTextInput
                id={whereTheIncidentHappenedDescription.id}
                name={whereTheIncidentHappenedDescription.name}
                label={whereTheIncidentHappenedDescription.label}
                placeholder="Describe the location"
                rows={4}
                maxLength={255}
                errorMessage="Please tell us where the incident happened"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FreeTextInput
              id={howTheIncidentHappened.id}
              name={howTheIncidentHappened.name}
              label={howTheIncidentHappened.label}
              placeholder="Describe how the incident occurred"
              rows={12}
              maxLength={2000}
              errorMessage="Please tell us how the incident happened"
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              id={werePoliceInvolved.id}
              name={werePoliceInvolved.name}
              label={werePoliceInvolved.label}
              options={YesNoImNotSure}
              errorMessage={RequiredOption}
              onChange={(e, v) => {
                resetField("policeReportNumber", { defaultValue: "" });

                trackCustomFormotivInput(werePoliceInvolved.label + " - " + v, "button", v);

                gtm(fieldTouched("Were the police involved"));
                gtm(event(`Were the police involved  - ${v}`));
              }}
            />
          </Grid>
          {hasPoliceDetails === YesNoImNotSure.Yes && (
            <Grid item xs={12}>
              <FreeTextInput
                id={policeReportNumber.id}
                name={policeReportNumber.name}
                label={policeReportNumber.label}
                placeholder="e.g. 9512345678"
                autoComplete={policeReportNumber.name}
                fullWidth
                rows={1}
                multiline={false}
                maxLength={PoliceReportNumberMaxLength}
                required={false}
                validationMessage={pleaseEnterAValidMessage("police report number")}
                optionalText={OptionalText.IfKnown}
                onClick={() => {
                  gtm(fieldTouched("Police report number"));
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "24px 0 32px 0" }}
              onClick={() => {
                if (
                  form.getFieldState(WhereAndHowQuestions.whereTheIncidentHappenedMap.name).error &&
                  googleMapsAvailable &&
                  !hasShownZoomError
                ) {
                  gtm(event("Please zoom in more so we can see where this happened"));
                  setHasShownZoomError(true);
                }
              }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
