import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  ContactNumberInput,
  FreeTextInput,
  FreeTextInputProps,
  OptionalText,
  RadioButtonGroup,
  pleaseEnterAValidMessage,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { StyledDivider } from "../../shared/components/Styled";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { CarLocation, CarLocationsExcludingHome, WheresYourCarQuestions, wheresYourCarPageTitle } from "./constants";
import { WheresYourCarFormProps } from "./types";

const { carLocation, businessName, businessContactNumber, carLocationDetails } = WheresYourCarQuestions;

export const WheresYourCarForm = ({ form, carLocationOptions, onSubmit }: WheresYourCarFormProps) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { isSubmitting },
  } = form;
  const selectedCarLocation = useWatch({ control, name: "carLocation" });

  const showBusinessDetails =
    selectedCarLocation === CarLocation.TowTruckHoldingYard || selectedCarLocation === CarLocation.Repairer;
  const showCarLocationDetails =
    selectedCarLocation !== CarLocation.YourHome && (selectedCarLocation !== undefined || !carLocationOptions);

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle title={wheresYourCarPageTitle} id="wheres-your-car-header" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            {carLocationOptions && (
              <Grid item xs={12}>
                <RadioButtonGroup
                  id={carLocation.id}
                  name={carLocation.name}
                  label={carLocation.label}
                  items={carLocationOptions}
                  onChange={(_, value: string) => {
                    resetField(businessName.name, { defaultValue: "" });
                    resetField(businessContactNumber.name, { defaultValue: "" });
                    resetField(carLocationDetails.name, { defaultValue: "" });
                    trackCustomFormotivInput(carLocation.label + " - " + value, "select-one", value);
                    gtm(fieldTouched("Your car is at"));
                    gtm(
                      event(`Your car is at - ${carLocationOptions.find((option) => option.value === value)?.label}`),
                    );
                  }}
                  fullwidth
                />
              </Grid>
            )}
            {showBusinessDetails && (
              <>
                <Grid item xs={12}>
                  <StyledDivider />
                  <Typography variant="h3" id="business-details-heading">
                    {selectedCarLocation === CarLocation.TowTruckHoldingYard
                      ? "Holding yard details"
                      : "Repairer details"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FreeTextInput
                    id={businessName.id}
                    name={businessName.name}
                    label={businessName.label}
                    fieldName="business name"
                    optionalText={OptionalText.IfKnown}
                    placeholder="Enter business name"
                    multiline={false}
                    maxLength={200}
                    required={false}
                    validationMessage={pleaseEnterAValidMessage("business name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContactNumberInput
                    name={businessContactNumber.name}
                    label={businessContactNumber.label}
                    required={false}
                    optional
                    optionalText={OptionalText.IfKnown}
                  />
                </Grid>
              </>
            )}
            {showCarLocationDetails && (
              <Grid item xs={12}>
                <FreeTextInput
                  id={carLocationDetails.id}
                  name={carLocationDetails.name}
                  rows={4}
                  maxLength={1000}
                  {...getCarLocationDetailsProps(selectedCarLocation)}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              id="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ marginTop: "36px", marginBottom: "16px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const getCarLocationDetailsProps = (
  carLocation?: CarLocationsExcludingHome,
): Pick<FreeTextInputProps, "label" | "sublabel" | "optionalText" | "required" | "placeholder" | "errorMessage"> => {
  const anyInformationIsHelpful = "Any information is helpful.";
  const enterAddress = "Enter address";
  const provideDetails = "Provide details";

  const carLocationDetailsLabel = WheresYourCarQuestions.carLocationDetails.label(carLocation);

  switch (carLocation) {
    case CarLocation.TowTruckHoldingYard:
    case CarLocation.Repairer:
      return {
        label: carLocationDetailsLabel,
        sublabel: anyInformationIsHelpful,
        required: false,
        optionalText: OptionalText.IfKnown,
        placeholder: enterAddress,
      };
    case CarLocation.Other:
      return {
        label: carLocationDetailsLabel,
        required: true,
        placeholder: enterAddress,
        errorMessage: "Please provide an address",
      };
    case CarLocation.IDontKnow:
      return {
        label: carLocationDetailsLabel,
        sublabel: anyInformationIsHelpful,
        required: false,
        optionalText: OptionalText.IfKnown,
        placeholder: provideDetails,
      };
    default:
      return {
        label: carLocationDetailsLabel,
        required: false,
        optionalText: OptionalText.IfKnown,
        placeholder: "Provide an address if you can",
      };
  }
};

export default WheresYourCarForm;
