import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { CreateClaimRequestCollisionScenario } from "raci-claims-motor-collision-clientproxy";
import {
  CardChoiceInput,
  InsurancePhoneNumber,
  NotificationCard,
  PhoneNumberLink,
  ToggleButtonGroup,
  YesNo,
  YesNoUnknown,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { useEffect } from "react";
import { FormProvider, useController, useWatch } from "react-hook-form";
import ClaimDetailsCard from "../../shared/components/ClaimDetailsCard";
import PageTitle from "../../shared/components/PageTitle";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import {
  AboutTheAccidentQuestions,
  OtherVehiclesInvolved,
  aboutTheAccidentPageTitle,
  getOtherVehiclesInvolvedOptions,
  singleVehicleCollisionScenarioOptions,
} from "./constants";
import { AboutTheAccidentFormProps, AboutTheAccidentFormValues } from "./types";

const { otherVehiclesInvolved, singleVehicleCollisionScenario, propertyDamaged, claimForDamageToOwnCar } =
  AboutTheAccidentQuestions;

export const AboutTheAccidentForm = ({
  form,
  hasComprehensiveCover,
  claimCreated,
  youCantClaimOnline,
  onSubmit,
}: AboutTheAccidentFormProps) => {
  const {
    control,
    resetField,
    unregister,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  const { field: otherVehiclesInvolvedFieldProps, fieldState: otherVehiclesInvolvedFieldState } = useController<
    AboutTheAccidentFormValues,
    "otherVehiclesInvolved"
  >({
    control,
    name: "otherVehiclesInvolved",
    rules: {
      required: { value: true, message: "Please select an option" },
    },
  });

  const otherVehiclesInvolvedValue = useWatch({ control, name: "otherVehiclesInvolved" });
  const svcScenarioValue = useWatch({ control, name: "singleVehicleCollisionScenario" });
  const propertyDamagedValue = useWatch({ control, name: "propertyDamaged" });
  const claimForDamageToOwnCarValue = useWatch({ control, name: "claimForDamageToOwnCar" });

  const isSingleVehicleClaim =
    hasComprehensiveCover &&
    (otherVehiclesInvolvedValue === OtherVehiclesInvolved.ImNotSure ||
      otherVehiclesInvolvedValue === OtherVehiclesInvolved.None);

  const isMultiVehicleClaim = otherVehiclesInvolvedValue === OtherVehiclesInvolved.One;

  const hitThirdPartyProperty =
    svcScenarioValue === CreateClaimRequestCollisionScenario.HitSomeoneElsesProperty ||
    svcScenarioValue === CreateClaimRequestCollisionScenario.HitAnotherPersonsPetOrAnimal ||
    svcScenarioValue === CreateClaimRequestCollisionScenario.SomethingElseHappened;

  const thirdPartyPropertyIsDamaged =
    propertyDamagedValue === YesNoUnknown.Yes || propertyDamagedValue === YesNoUnknown.Unknown;

  const showClaimForDamageToOwnCar =
    hasComprehensiveCover && (isMultiVehicleClaim || (hitThirdPartyProperty && thirdPartyPropertyIsDamaged));

  const showNoDamageToClaimFor =
    hitThirdPartyProperty && propertyDamagedValue === YesNoUnknown.Unknown && claimForDamageToOwnCarValue === YesNo.No;

  const showYouCantChangeThis = !showNoDamageToClaimFor && !youCantClaimOnline;

  useEffect(() => {
    if (showNoDamageToClaimFor) {
      gtm(event("Looks like there's nothing to claim for"));
    }
  }, [showNoDamageToClaimFor]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageTitle title={aboutTheAccidentPageTitle} id="header" />
          </Grid>
          <Grid item xs={12}>
            <RacwaSelect
              {...otherVehiclesInvolvedFieldProps}
              id={otherVehiclesInvolved.id}
              label={otherVehiclesInvolved.label}
              sublabel={otherVehiclesInvolved.subLabel}
              value={otherVehiclesInvolvedFieldProps.value ?? "Select an option"}
              error={!!otherVehiclesInvolvedFieldState.error}
              helperText={otherVehiclesInvolvedFieldState.error?.message ?? ""}
              inputRef={otherVehiclesInvolvedFieldProps.ref}
              disabled={youCantClaimOnline || claimCreated}
              onChange={({ target: { value } }) => {
                otherVehiclesInvolvedFieldProps.onChange(value as OtherVehiclesInvolved);
                resetField(singleVehicleCollisionScenario.name);
                resetField(claimForDamageToOwnCar.name);
                unregister([singleVehicleCollisionScenario.name, claimForDamageToOwnCar.name]);
                trackCustomFormotivInput(otherVehiclesInvolved.label + " - " + value, "button", value);
                gtm(event(`Number of other vehicles involved - ${value}`));
              }}
              onOpen={() => gtm(fieldTouched(otherVehiclesInvolved.label))}
              fullWidth
            >
              <MenuItem key="Select an option" value="Select an option" disabled selected hidden>
                Select an option
              </MenuItem>
              {getOtherVehiclesInvolvedOptions(hasComprehensiveCover).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </RacwaSelect>
            {youCantClaimOnline && (
              <Grid item xs={12} sx={{ margin: "16px 0 16px 0" }}>
                <NotificationCard
                  severity="error"
                  message={{
                    title: "Sorry, you can't claim online",
                    content: (
                      <Typography>
                        Please call us on{" "}
                        <PhoneNumberLink phoneNumber={InsurancePhoneNumber} id="insurance-phone-number-link" /> so we
                        can help you with your claim.
                      </Typography>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
          {isSingleVehicleClaim && (
            <Grid item xs={12}>
              <CardChoiceInput
                key={otherVehiclesInvolvedValue}
                id={singleVehicleCollisionScenario.id}
                name={singleVehicleCollisionScenario.name}
                label={singleVehicleCollisionScenario.label}
                options={singleVehicleCollisionScenarioOptions}
                variant="landscape"
                alignItems="left"
                disabled={claimCreated}
                onChange={(option) => {
                  resetField(propertyDamaged.name);
                  resetField(claimForDamageToOwnCar.name);
                  unregister([propertyDamaged.name, claimForDamageToOwnCar.name]);
                  trackCustomFormotivInput(
                    singleVehicleCollisionScenario.label + " - " + option.label,
                    "button",
                    option.label,
                  );
                  gtm(event(`The accident was with - ${option.label}`));
                  gtm(fieldTouched("The accident was with"));
                }}
              />
            </Grid>
          )}
          {hitThirdPartyProperty && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                key={svcScenarioValue}
                id={propertyDamaged.id}
                name={propertyDamaged.name}
                label={propertyDamaged.label({ singleVehicleCollisionScenario: svcScenarioValue })}
                options={YesNoUnknown || undefined}
                disabled={claimCreated}
                onChange={(e, option) => {
                  resetField(claimForDamageToOwnCar.name);
                  unregister(claimForDamageToOwnCar.name);
                  const message = propertyDamaged.label({ singleVehicleCollisionScenario: svcScenarioValue });
                  trackCustomFormotivInput(message + " - " + option, "button", option);
                  gtm(fieldTouched(message));
                  gtm(event(`${message} - ${option}`));
                }}
              />
            </Grid>
          )}
          {showClaimForDamageToOwnCar && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                id={claimForDamageToOwnCar.id}
                name={claimForDamageToOwnCar.name}
                label={claimForDamageToOwnCar.label}
                options={YesNo}
                disabled={claimCreated}
                onChange={(e, value) => {
                  trackCustomFormotivInput(claimForDamageToOwnCar.label + " - " + value, "button", value);
                  gtm(fieldTouched("Do you want to claim for the damage to your car"));
                  gtm(event(`Do you want to claim for the damage to your car - ${value}`));
                }}
              />
            </Grid>
          )}
          {showNoDamageToClaimFor && (
            <Grid item xs={12} sx={{ margin: "16px 0 16px 0" }}>
              <NotificationCard
                severity="error"
                message={{
                  title: "Looks like there's no damage to claim for",
                  content: <Typography>Please check your answers.</Typography>,
                }}
              />
            </Grid>
          )}
          {showYouCantChangeThis && (
            <Grid item xs={12}>
              <Box py={2}>
                <ClaimDetailsCard claimCreated={claimCreated} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              id="submit"
              data-testid="submit"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || youCantClaimOnline || showNoDamageToClaimFor}
              sx={{ margin: "8px 0 16px 0" }}
              fullWidth
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
