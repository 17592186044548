import { Button, Grid, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { SelectionGroup, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import RepairerPerks from "./components/RepairerPerks";
import { RepairerOptionsQuestions } from "./constants";
import { RepairerOptionsFormProps } from "./types";

const nextButtonId = "submit";
const { selectedRepairer } = RepairerOptionsQuestions;

export const RepairerOptionsForm = ({ form, repairers, hasHireCarCover, onSubmit }: RepairerOptionsFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="repairer-options-header" title="Repairer options" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <SelectionGroup id={selectedRepairer.id} name={selectedRepairer.name} exclusive>
              <SelectionGroup.Column>
                {repairers.map(
                  (
                    { id, repairerName, repairerAddress, isRapidRepairer, isReadyDriveRepairer, isEvRepairer },
                    index,
                  ) => (
                    <SelectionGroup.SelectionCard
                      key={id}
                      id={`repairer-${index}`}
                      value={id}
                      title={repairerName}
                      finePrint={repairerAddress}
                      behaviour="radio"
                      onSelect={() => {
                        trackCustomFormotivInput("Accepted offered repairer - Yes", "button", repairerName);
                        gtm(fieldTouched("Repairer options"));
                        gtm(event("Repairer options - Offered repairer selected"));
                      }}
                    >
                      <RepairerPerks
                        electricVehicleRepairer={!!isEvRepairer}
                        fastTrackedAssessment={!!isRapidRepairer}
                        complimentaryCarHire={!hasHireCarCover && isReadyDriveRepairer}
                      />
                    </SelectionGroup.SelectionCard>
                  ),
                )}
                <SelectionGroup.SelectionCard
                  id="get-a-quote"
                  value="Get a quote"
                  title="Get a quote from your own repairer"
                  behaviour="radio"
                  onSelect={() => {
                    trackCustomFormotivInput("Accepted offered repairer - No", "button", false);
                    gtm(fieldTouched("Repairer options"));
                    gtm(event("Repairer options - Get a quote from your own repairer"));
                  }}
                >
                  <Typography variant="body1">
                    Please tell your repairer to send us the quote for us to review.
                  </Typography>
                </SelectionGroup.SelectionCard>
              </SelectionGroup.Column>
            </SelectionGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "36px 0px 16px 0px" }}
              disabled={isSubmitting}
            >
              Submit claim
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RepairerOptionsForm;
