import { faAngleRight, faClipboard, faClock, faList, faUser } from "@fortawesome/free-solid-svg-icons";
import { Button, Divider, Grid, Link, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { FinePrint } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { BaseDialogBox, CardWithIcon, CardWithIconProps } from "raci-react-library";
import { useEffect, useState } from "react";
import { StyledGrid, SubHeadingContainer, SubHeadingTypography, TitleTypography } from "./styled";
import { BeforeYouStartFormProps, RedirectType } from "./types";

const loginRegisterOrNext = "Log in and claim, Register now or Next";

type CardInfo = {
  icon: CardWithIconProps["icon"];
  title: string;
  marginTop: string;
};

const cardInfo: CardInfo[] = [
  { icon: faAngleRight, title: "Firstly, we hope you're okay.", marginTop: "5px" },
  { icon: faList, title: "Fill in the form to the best of your knowledge.", marginTop: "5px" },
  { icon: faClock, title: "This may take around 15 minutes.", marginTop: "5px" },
  { icon: faUser, title: "You'll need the full name and date of birth of the driver of your car.", marginTop: "5px" },
  { icon: faClipboard, title: "You don't need a police or crash report to start a claim.", marginTop: "5px" },
];

export const BeforeYouStartForm = ({ isLoggedIn, disabled, privacyUrl, handleRedirect }: BeforeYouStartFormProps) => {
  const [showAllowEnoughTimeDialog, setShowAllowEnoughTimeDialog] = useState(false);
  const [redirectType, setRedirectType] = useState<RedirectType>(isLoggedIn ? RedirectType.Next : RedirectType.Login);

  useEffect(() => {
    showAllowEnoughTimeDialog && gtm(event("Modal - Please allow enough time to finish your claim"));
  }, [showAllowEnoughTimeDialog]);

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <SubHeadingContainer item xs={12}>
        <SubHeadingTypography variant="h2">Before you start</SubHeadingTypography>
      </SubHeadingContainer>
      <StyledGrid container item spacing={1} justifyContent="center">
        {cardInfo.map((card, index) => (
          <Grid item xs={12} key={card.title}>
            <CardWithIcon
              icon={card.icon}
              iconSize="18px"
              iconStyle={{ marginTop: card.marginTop }}
              title={<TitleTypography>{card.title}</TitleTypography>}
              border={false}
              spacing={2}
              sx={{ paddingLeft: "0px", paddingRight: "0px", color: colors.dieselDeep }}
            />
            {index !== cardInfo.length - 1 && <Divider />}
          </Grid>
        ))}
        <Grid item xs={12}>
          <FinePrint sx={{ textAlign: "center", color: ({ palette }) => palette.secondary.light }}>
            You can find details of your excess in your policy documents. All personal information is collected
            according to our{" "}
            <Link
              target="_blank"
              onClick={() => gtm(event("Privacy Policy"))}
              href={privacyUrl}
              rel="noreferrer noopener"
            >
              Privacy Policy
            </Link>
            .
          </FinePrint>
        </Grid>
        {isLoggedIn ? (
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              sx={{ marginTop: "24px" }}
              disabled={disabled}
              onClick={(value) => {
                setRedirectType(RedirectType.Next);
                setShowAllowEnoughTimeDialog(true);
                gtm(fieldTouched(loginRegisterOrNext));
                gtm(event("Next"));
                window.formotiv?.inputActivity?.(window.formotivConfig, "isLoggedIn", "buttongroup", value);
              }}
              data-testid="next"
              fullWidth
            >
              Next
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                sx={{ marginTop: "24px" }}
                disabled={disabled}
                onClick={(value) => {
                  setRedirectType(RedirectType.Login);
                  setShowAllowEnoughTimeDialog(true);
                  gtm(fieldTouched(loginRegisterOrNext));
                  gtm(event("Log in and claim"));
                  window.formotiv?.inputActivity?.(window.formotivConfig, "Log in and claim", "button", value);
                }}
                data-testid="login-register"
                fullWidth
              >
                Log in and claim
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px", marginTop: "8px" }}
            >
              <Typography sx={{ fontWeight: 400 }}>Don't have an account?</Typography>
              <Button
                variant="text"
                sx={{
                  padding: "0px",
                  textDecoration: "underline",
                  color: colors.linkBlue,
                  "&:hover": { backgroundColor: "transparent", textDecoration: "underline" },
                }}
                onClick={(value) => {
                  setRedirectType(RedirectType.Register);
                  setShowAllowEnoughTimeDialog(true);
                  gtm(fieldTouched(loginRegisterOrNext));
                  gtm(event("Register now"));
                  window.formotiv?.inputActivity?.(window.formotivConfig, "Register now", "button", value);
                }}
              >
                Register now
              </Button>
            </Grid>
          </>
        )}
      </StyledGrid>
      <BaseDialogBox
        id="allow-enough-time-dialog"
        showDialog={showAllowEnoughTimeDialog}
        setShowDialog={setShowAllowEnoughTimeDialog}
        title="Please allow enough time to finish your claim"
        content={
          <Typography paragraph sx={{ marginBottom: "0px" }}>
            You won't be able to save your claim as you go, so it's better to complete it all at once.
          </Typography>
        }
        buttons={
          <>
            <Button
              id="start-claim-btn"
              type="button"
              color="primary"
              variant="contained"
              onClick={(value) => {
                setShowAllowEnoughTimeDialog(false);
                handleRedirect(redirectType);
                gtm(event("Start claim"));
                window.formotiv?.inputActivity?.(window.formotivConfig, "Start claim", "button", value);
              }}
              fullWidth
            >
              Start claim
            </Button>
            <Button
              id="ill-come-back-later-btn"
              type="button"
              color="info"
              variant="contained"
              sx={{ marginTop: "24px" }}
              onClick={(value) => {
                setShowAllowEnoughTimeDialog(false);
                gtm(event("I'll come back later"));
                window.formotiv?.inputActivity?.(window.formotivConfig, "I'll come back later", "button", value);
              }}
              fullWidth
            >
              I'll come back later
            </Button>
          </>
        }
      />
    </Grid>
  );
};

export default BeforeYouStartForm;
