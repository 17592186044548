import {
  ClaimsMotorCollisionApiException,
  CreateClaimRequestCollisionScenario,
  CreateClaimRequestDirectionOfTravel,
} from "raci-claims-motor-collision-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { MultiVehicleCollisionScenario, SingleVehicleCollisionScenario } from "../../../../shared/types";
import { OtherVehiclesInvolved } from "../../../AboutTheAccident/constants";
import { AboutTheAccidentState } from "../../../AboutTheAccident/types";
import { ClaimForDamageToOwnCarYesNoMap, OtherVehiclesInvolvedMap } from "../../constants";
import { MoreAboutTheAccidentFormProps, MoreAboutTheAccidentFormValues, MoreAboutTheAccidentState } from "../../types";

export const useMoreAboutTheAccident = (): MoreAboutTheAccidentFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const location = useLocation();
  const apiClient = useBffApiClient();
  const claimState = useClaimState();
  const { otherVehiclesInvolved, singleVehicleCollisionScenario, claimForDamageToOwnCar } =
    useGetSessionState<AboutTheAccidentState>(FormRoute.AboutTheAccident);
  const [moreAboutTheAccidentState, setMoreAboutTheAccidentState] = useSessionState<MoreAboutTheAccidentState>();

  const claimCreated = !!moreAboutTheAccidentState.claimNumber;

  const form = useForm<MoreAboutTheAccidentFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: moreAboutTheAccidentState,
  });

  const onSubmit: MoreAboutTheAccidentFormProps["onSubmit"] = async (formValues) => {
    const { directionOfTravel, multiVehicleCollisionScenario } = formValues;

    if (claimCreated) {
      return navigate(FormRoute.WhereAndHow);
    }

    // This condition narrows the type of otherVehiclesInvolved so that it is valid
    // when accessing values in the OtherVehiclesInvolvedMap
    if (
      otherVehiclesInvolved === undefined ||
      otherVehiclesInvolved === OtherVehiclesInvolved.Two ||
      otherVehiclesInvolved === OtherVehiclesInvolved.ThreeOrMore
    ) {
      return navigate(ErrorRoute.SystemUnavailable);
    }

    try {
      setBackdrop(true);

      setMoreAboutTheAccidentState({
        ...formValues,
        isSubmitted: true,
        isCompleted: false,
      });

      const response = (
        await apiClient.createClaim({
          otherVehiclesInvolved: OtherVehiclesInvolvedMap[`${otherVehiclesInvolved}`],
          directionOfTravel,
          collisionScenario: getCollisionScenario({
            directionOfTravel,
            singleVehicleCollisionScenario,
            multiVehicleCollisionScenario,
          }),
          claimForDamageToOwnCar: claimForDamageToOwnCar
            ? ClaimForDamageToOwnCarYesNoMap[`${claimForDamageToOwnCar}`]
            : undefined,
        })
      ).result;

      setMoreAboutTheAccidentState({
        ...formValues,
        ...response,
        isSubmitted: true,
        isCompleted: true,
      });

      window.formotivConfig.state.ptv1 = response.claimNumber;

      navigate(FormRoute.WhereAndHow);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /claims/claim`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  useEffect(() => {
    const { directionOfTravel, forwardScenario, multiVehicleCollisionScenario, isSubmitted } =
      moreAboutTheAccidentState;

    if (isSubmitted && !claimCreated) {
      directionOfTravel !== undefined
        ? onSubmit({ directionOfTravel, forwardScenario, multiVehicleCollisionScenario })
        : navigate(ErrorRoute.SystemUnavailable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    claimState,
    claimCreated,
    onSubmit,
  };
};

const getCollisionScenario = ({
  directionOfTravel,
  singleVehicleCollisionScenario,
  multiVehicleCollisionScenario,
}: {
  directionOfTravel: CreateClaimRequestDirectionOfTravel;
  singleVehicleCollisionScenario?: SingleVehicleCollisionScenario;
  multiVehicleCollisionScenario?: MultiVehicleCollisionScenario;
}): CreateClaimRequestCollisionScenario | undefined => {
  if (singleVehicleCollisionScenario) {
    return singleVehicleCollisionScenario;
  }

  if (multiVehicleCollisionScenario) {
    return multiVehicleCollisionScenario;
  }

  return directionOfTravel === CreateClaimRequestDirectionOfTravel.Forward
    ? CreateClaimRequestCollisionScenario.SomethingElseHappened
    : undefined;
};

export default useMoreAboutTheAccident;
