import { ContactDetailsDisplay, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { ContactDetailsFormProps } from "./types";

export const ContactDetailsForm = ({
  isLoading,
  firstName,
  phoneType,
  maskedPhoneNumber,
  maskedEmail,
  onSubmit,
}: ContactDetailsFormProps) => {
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  if (isLoading) return null;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formotivOnSubmitWrapper(onSubmit)({
      contactNumber: maskedPhoneNumber,
      email: maskedEmail,
    });
  };

  return (
    <form onSubmit={handleSubmit} action="#">
      <ContactDetailsDisplay
        firstName={firstName ?? ""}
        phoneType={phoneType}
        maskedPhoneNumber={maskedPhoneNumber ?? ""}
        maskedEmail={maskedEmail ?? ""}
        onConfirmedCorrect={() => {
          trackCustomFormotivInput("confirmedContactDetails - Yes", "button", "Confirmed");
          onSubmit({ contactNumber: maskedPhoneNumber, email: maskedEmail });
        }}
        updateContactDetailsUrl={
          // Note: The redirect URL that is sent to MyRAC cannot have protocol in it, due to Sitecore Login not being able to handle it
          process.env.REACT_APP_MY_RAC_EDIT_CONTACT_DETAILS_PAGE
            ? `${process.env.REACT_APP_MY_RAC_EDIT_CONTACT_DETAILS_PAGE}?return_url=${window.location.href.replace("https://", "")}`
            : ""
        }
      />
    </form>
  );
};

export default ContactDetailsForm;
