import { Box, Button } from "@mui/material";
import { BASE_PDS_URL, StartClaim, trackCustomFormotivInput, useFormotiv } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import ClaimDetailsCard from "../../shared/components/ClaimDetailsCard";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import IneligibleForClaimError from "./components/IneligibleForClaimError";
import SimilarClaimDialog from "./components/SimilarClaimDialog";
import { StartYourClaimQuestions } from "./constants";
import { StartYourClaimFormProps } from "./types";

const { date: dateQuestion, time: timeQuestion } = StartYourClaimQuestions;

export const StartYourClaimForm = ({
  form,
  policyDetails,
  claimNumber,
  ineligibleForClaim,
  showSimilarClaimDialog,
  error,
  onSubmit,
  onSimilarClaimAllowed,
}: StartYourClaimFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const nextButtonId = "submit-button";
  const claimCreated = !!claimNumber;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        {policyDetails && (
          <StartClaim
            policy={{
              policyNumber: policyDetails?.policyNumber ?? "",
              policyTitle: `${policyDetails?.year} ${policyDetails?.make}`,
              policySubtitle: policyDetails?.registrationNumber,
            }}
            pds={process.env.REACT_APP_RAC_PDS_PED_URL ?? BASE_PDS_URL}
            disabled={ineligibleForClaim || claimCreated}
            timeEnabled
            dateInputLabel={dateQuestion.label}
            timeInputLabel={timeQuestion.label}
            claimNumber={claimNumber}
            onChange={(value) => {
              trackCustomFormotivInput(dateQuestion.label + new Date(value).toLocaleDateString(), "date", value);
            }}
          >
            <>
              <Box sx={{ paddingTop: "8px", paddingBottom: "24px" }}>
                {error ? (
                  <IneligibleForClaimError
                    reason={error.ineligibleReason}
                    duplicateClaimNumber={error.duplicateOrSimilarClaimNumber}
                  />
                ) : (
                  <ClaimDetailsCard claimCreated={claimCreated} />
                )}
              </Box>
              <Button
                type="submit"
                id={nextButtonId}
                data-testid={nextButtonId}
                color="primary"
                variant="contained"
                fullWidth
                disabled={ineligibleForClaim || isSubmitting}
                sx={{ margin: "16px 0px 40px 0px" }}
              >
                Next
              </Button>
            </>
          </StartClaim>
        )}

        <SimilarClaimDialog
          showDialog={showSimilarClaimDialog}
          eventDate={error?.similarClaimEventDate ?? new Date().toISOString()}
          claimNumber={error?.duplicateOrSimilarClaimNumber}
          onButtonClick={handleSubmit(onSimilarClaimAllowed)}
        />
      </form>
    </FormProvider>
  );
};

export default StartYourClaimForm;
