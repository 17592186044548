import { Button, Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { InsuranceCompany } from "raci-claims-motor-collision-clientproxy";

import {
  FreeTextInput,
  OptionalText,
  RequiredOption,
  ToggleButtonGroup,
  YesNoUnknown,
  pleaseEnterAValidMessage,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { FormProvider, useController, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { MoreThirdPartyDetailsQuestions, moreThirdPartyDetailsPageTitle } from "./constants";
import { MoreThirdPartyDetailsFormProps, MoreThirdPartyDetailsFormValues } from "./types";

const titleId = "third-party-owner-details-header";
const nextButtonId = "submit-button";

const { damageDescription, isDriverTheOwner, ownersClaimNumber, ownersInsuranceCompany, ownersNameAndContactDetails } =
  MoreThirdPartyDetailsQuestions;

export const MoreThirdPartyDetailsForm = ({
  isSingleVehicleCollisionClaim,
  insuranceCompanies,
  form,
  onSubmit,
}: MoreThirdPartyDetailsFormProps) => {
  const {
    control,
    handleSubmit,
    unregister,
    formState: { isSubmitting },
  } = form;

  const isDriverTheOwnerValue = useWatch({ control, name: isDriverTheOwner.name });

  const damageDescriptionSubLabel = isSingleVehicleCollisionClaim
    ? ""
    : "What parts were damaged, e.g. right tail-light cracked.";

  const { field: ownersInsuranceCompanyFieldProps, fieldState: ownersInsuranceCompanyFieldState } = useController<
    MoreThirdPartyDetailsFormValues,
    "ownersInsuranceCompany"
  >({
    control,
    name: "ownersInsuranceCompany",
  });

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle
              title={moreThirdPartyDetailsPageTitle({ isSingleVehicleCollisionClaim })}
              id={titleId}
              showClaimNumber
            />
          </Grid>
          {!isSingleVehicleCollisionClaim && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                id={isDriverTheOwner.id}
                name={isDriverTheOwner.name}
                label={isDriverTheOwner.label}
                options={YesNoUnknown}
                errorMessage={RequiredOption}
                onChange={(_, value) => {
                  if (value === YesNoUnknown.No) {
                    unregister("ownersNameAndContactDetails");
                  }

                  trackCustomFormotivInput(isDriverTheOwner.label + " - " + value, "button", value);
                  gtm(fieldTouched(isDriverTheOwner.label));
                  gtm(event(`${isDriverTheOwner.label} - ${value}`));
                }}
              />
            </Grid>
          )}
          {!isSingleVehicleCollisionClaim && isDriverTheOwnerValue === YesNoUnknown.No && (
            <Grid item xs={12}>
              <FreeTextInput
                id={ownersNameAndContactDetails.id}
                name={ownersNameAndContactDetails.name}
                label={ownersNameAndContactDetails.label}
                sublabel="Include company name if relevant."
                optionalText={OptionalText.IfKnown}
                placeholder="Name and details"
                fullWidth
                rows={4}
                maxLength={200}
                required={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RacwaAutocomplete
              {...ownersInsuranceCompanyFieldProps}
              id={ownersInsuranceCompany.id}
              label={ownersInsuranceCompany.label({ isSingleVehicleCollisionClaim })}
              placeholder="Please select"
              options={insuranceCompanies || ([] as Required<InsuranceCompany>[])}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
              isOptionEqualToValue={(option, value) => option.externalCode === value?.externalCode}
              freeSolo={!insuranceCompanies.length}
              fullWidth
              autoHighlight
              clearOnEscape
              required={false}
              value={ownersInsuranceCompanyFieldProps.value ?? null}
              textFieldProps={{
                name: ownersInsuranceCompany.id,
                "data-testid": ownersInsuranceCompany.id,
                inputProps: { maxLength: 50 },
              }}
              onChange={(_, v) => {
                ownersInsuranceCompanyFieldProps.onChange(v);
              }}
              error={!!ownersInsuranceCompanyFieldState.error}
              helperText={ownersInsuranceCompanyFieldState.error?.message}
              optional
              optionalText={OptionalText.IfKnown}
              onBlur={(_) => gtm(fieldTouched(ownersInsuranceCompany.label({ isSingleVehicleCollisionClaim })))}
            />
          </Grid>
          <Grid item xs={12}>
            <FreeTextInput
              id={ownersClaimNumber.id}
              name={ownersClaimNumber.name}
              label={ownersClaimNumber.label}
              placeholder="Claim number"
              fullWidth
              optionalText={OptionalText.IfKnown}
              required={false}
              rows={1}
              maxLength={100}
              multiline={false}
              validationMessage={pleaseEnterAValidMessage("claim number")}
            />
          </Grid>
          <Grid item xs={12}>
            <FreeTextInput
              id={damageDescription.id}
              name={damageDescription.name}
              label={damageDescription.label({ isSingleVehicleCollisionClaim })}
              sublabel={damageDescriptionSubLabel}
              optionalText={OptionalText.IfKnown}
              placeholder="Please describe"
              fullWidth
              rows={4}
              maxLength={500}
              required={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "28px 0px 16px 0px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
